import { runTimeConfig } from './shared/runtime-config';

export async function initNR() {
  if (!runTimeConfig.BROWSER_NEWRELIC.ENABLED) return;

  const { enableNewRelic } = await import('./features/NewRelic/enableNewRelic');
  const NR = enableNewRelic(runTimeConfig.BROWSER_NEWRELIC);
  if (NR) {
    const script = document.createElement('script');
    script.innerHTML = NR;
    document.head.appendChild(script);
  }
}
