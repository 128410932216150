import { RemixBrowser } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';
import { startTransition } from 'react';

import { setupApiClientInterceptors } from './shared/axios';
import { initNR } from './newrelic.client';
import { initClientI18n } from './i18n/i18n.client';

setupApiClientInterceptors();

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

async function hydrate() {
  const { I18nProvider } = await initClientI18n();

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nProvider>
        <RemixBrowser />
      </I18nProvider>
    );

    if (process.env.IS_TEST !== 'true') {
      initNR();
    }
  });
}
