import * as React from 'react';

import i18next, { type InitOptions } from 'i18next';
import {
  I18nextProvider,
  I18nextProviderProps,
  initReactI18next,
} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getInitialNamespaces } from 'remix-i18next';
import { i18nConfig } from './config';

// https://www.i18next.com/how-to/backend-fallback#you-can-also-lazy-load-the-in-memory-translations-i.e.-when-using-webpack
const Backend = resourcesToBackend(
  (lng: string, ns: string) => import(`../../public/locales/${lng}/${ns}.json`)
);

export async function initClientI18n() {
  let ns: InitOptions['ns'];
  try {
    // We might or might not in Remix context (window.__remixRouteModules) hence try/catch
    // This function detects the namespaces your routes rendered while SSR use
    ns = getInitialNamespaces();
  } catch (err) {}

  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(LanguageDetector) // Setup a client-side language detector
    .use(Backend) // Setup your backend
    .init({
      ...i18nConfig,
      ...(ns && { ns }),
      // https://github.com/i18next/i18next-browser-languageDetector?tab=readme-ov-file#detector-options
      detection: {
        order: ['querystring', 'htmlTag'],
        lookupQuerystring: i18nConfig.searchParamKey,
        // don't cache detection result for now
        caches: [],
      },
    });

  function I18nProvider({ children }: { children: React.ReactNode }) {
    return React.createElement<I18nextProviderProps>(
      I18nextProvider,
      { i18n: i18next, defaultNS: i18nConfig.defaultNS },
      children
    );
  }

  return { I18nProvider };
}
